@import (reference) "_sitevariables.less";
@import "_base.less";

body.has-old-js {
  .start-overlay {
    display: none;
  }

  .start-overlay-old-js {
    display: block;
  }
}

.start-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 10000;
  background-color: @holte-normal;

  .start-overlay-welcome {
    margin: auto;
    width: 300px;
    position: relative;
    top: calc(~"50% - 80px");
    text-align: center;

    img {
      display: inline-block;
    }

    .floatingCirclesG {
      margin: auto;
    }
  }
}

.start-overlay-old-js {
  .start-overlay;

  // override default
  display: none;

  color: white;
  font-size: large;

  .start-overlay-welcome {
    width: 100%;
  }
}

.floatingCirclesG {
  position: relative;
  width: 80px;
  height: 80px;
  -moz-transform: scale(0.6);
  -webkit-transform: scale(0.6);
  -ms-transform: scale(0.6);
  -o-transform: scale(0.6);
  transform: scale(0.6);
}

.f_circleG {
  position: absolute;
  background-color: #131720;
  height: 15px;
  width: 15px;
  -moz-border-radius: 7px;
  -moz-animation-name: f_fadeG;
  -moz-animation-duration: 1.04s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-direction: linear;
  -webkit-border-radius: 7px;
  -webkit-animation-name: f_fadeG;
  -webkit-animation-duration: 1.04s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: linear;
  -ms-border-radius: 7px;
  -ms-animation-name: f_fadeG;
  -ms-animation-duration: 1.04s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-direction: linear;
  -o-border-radius: 7px;
  -o-animation-name: f_fadeG;
  -o-animation-duration: 1.04s;
  -o-animation-iteration-count: infinite;
  -o-animation-direction: linear;
  border-radius: 7px;
  animation-name: f_fadeG;
  animation-duration: 1.04s;
  animation-iteration-count: infinite;
  animation-direction: linear;
}

#frotateG_01 {
  left: 0;
  top: 33px;
  -moz-animation-delay: 0.39s;
  -webkit-animation-delay: 0.39s;
  -ms-animation-delay: 0.39s;
  -o-animation-delay: 0.39s;
  animation-delay: 0.39s;
}

#frotateG_02 {
  left: 9px;
  top: 9px;
  -moz-animation-delay: 0.52s;
  -webkit-animation-delay: 0.52s;
  -ms-animation-delay: 0.52s;
  -o-animation-delay: 0.52s;
  animation-delay: 0.52s;
}

#frotateG_03 {
  left: 33px;
  top: 0;
  -moz-animation-delay: 0.65s;
  -webkit-animation-delay: 0.65s;
  -ms-animation-delay: 0.65s;
  -o-animation-delay: 0.65s;
  animation-delay: 0.65s;
}

#frotateG_04 {
  right: 9px;
  top: 9px;
  -moz-animation-delay: 0.78s;
  -webkit-animation-delay: 0.78s;
  -ms-animation-delay: 0.78s;
  -o-animation-delay: 0.78s;
  animation-delay: 0.78s;
}

#frotateG_05 {
  right: 0;
  top: 33px;
  -moz-animation-delay: 0.91s;
  -webkit-animation-delay: 0.91s;
  -ms-animation-delay: 0.91s;
  -o-animation-delay: 0.91s;
  animation-delay: 0.91s;
}

#frotateG_06 {
  right: 9px;
  bottom: 9px;
  -moz-animation-delay: 1.04s;
  -webkit-animation-delay: 1.04s;
  -ms-animation-delay: 1.04s;
  -o-animation-delay: 1.04s;
  animation-delay: 1.04s;
}

#frotateG_07 {
  left: 33px;
  bottom: 0;
  -moz-animation-delay: 1.17s;
  -webkit-animation-delay: 1.17s;
  -ms-animation-delay: 1.17s;
  -o-animation-delay: 1.17s;
  animation-delay: 1.17s;
}

#frotateG_08 {
  left: 9px;
  bottom: 9px;
  -moz-animation-delay: 1.3s;
  -webkit-animation-delay: 1.3s;
  -ms-animation-delay: 1.3s;
  -o-animation-delay: 1.3s;
  animation-delay: 1.3s;
}

@-moz-keyframes f_fadeG {
  0% {
    background-color: #e1e8e4;
  }

  100% {
    background-color: #131720;
  }
}

@-webkit-keyframes f_fadeG {
  0% {
    background-color: #e1e8e4;
  }

  100% {
    background-color: #131720;
  }
}

@-ms-keyframes f_fadeG {
  0% {
    background-color: #e1e8e4;
  }

  100% {
    background-color: #131720;
  }
}

@-o-keyframes f_fadeG {
  0% {
    background-color: #e1e8e4;
  }

  100% {
    background-color: #131720;
  }
}

@keyframes f_fadeG {
  0% {
    background-color: #e1e8e4;
  }

  100% {
    background-color: #131720;
  }
}
