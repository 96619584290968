/// <reference path="Site.less" />

a {
  cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  margin-top: 0;
}

html,
body {
  height: 100%;
  margin: 0;
}

html {
  background-color: @main-background-color;
  overflow: hidden;
  padding: 0;
}
